<template>
    <section v-if="!wide" class="section-visual">
        <template v-if="image">
            <v-img :src="image">
                <v-container class="container--image">
                    <v-sheet color="white" class="section-visual__text">
                        <div>
                            <v-breadcrumbs :items="breadcrumbs">
                                <template v-slot:divider>
                                    <v-icon color="#333">mdi-chevron-right</v-icon>
                                </template>
                            </v-breadcrumbs>
                            <h2 class="tit tit--lg font-tertiary font-weight-regular">
                                {{ title }}
                            </h2>
                        </div>
                    </v-sheet>
                </v-container>
            </v-img>
        </template>
        <template v-else>
            <v-sheet color="grey lighten-5" class="section-visual__sheet">
                <v-container>
                    <v-breadcrumbs :items="breadcrumbs" class="px-0">
                        <template v-slot:divider>
                            <v-icon color="#333">mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                    <h2 class="tit tit--lg tit--center font-tertiary font-weight-regular">
                        {{ title }}
                    </h2>
                </v-container>
            </v-sheet>
        </template>
    </section>

    <section v-else class="section-visual section-visual--wide">
        <v-img :src="image" class="d-flex align-end">
            <v-sheet color="white" class="d-inline-flex px-12px pt-16px px-md-80px pt-md-32px">
                <div>
                    <v-breadcrumbs :items="breadcrumbs" class="pa-0 line-height-2">
                        <template v-slot:divider>
                            <v-icon color="#333">mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                    <h2 class="tit tit--lg font-tertiary font-weight-regular">
                        {{ title }}
                    </h2>
                </div>
            </v-sheet>
        </v-img>
    </section>
</template>

<script>
export default {
    props: {
        image: { type: String },
        wide: { type: Boolean, default: false },
        title: { type: String },
        breadcrumbs: { type: Array, default: [] },
    },
};
</script>

<style lang="scss" scoped>
.section-visual {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    .container {
        height: 100%;
        &--image {
            display: flex;
            align-items: flex-end;
        }
    }
    .tit {
        text-transform: uppercase;
    }
    &__text {
        display: flex;
        align-items: flex-end;
        aspect-ratio: 816/125;
        width: 100%;
        max-width: 816px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -100vw;
            top: 0;
            width: 100vw;
            height: 100%;
            background-color: inherit;
        }
    }
    .tit--center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .v-breadcrumbs {
        color: #333;
    }
    &__sheet {
        height: 170px;
    }
}

.section-visual--wide {
    .v-sheet {
        min-width: 460px;
    }
}
@media (min-width: 768px) {
    .section-visual {
        &__sheet {
            height: 254px;
        }
    }
    .section-visual--wide {
        .v-sheet {
            min-width: 628px;
        }
    }
}
</style>