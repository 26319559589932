var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "grey lighten-5 my-20px my-md-40px py-40px py-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h2', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실 제품 살펴보기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("시대를 초월한 우아함, 혁신적인 디자인, 뛰어난 품질: "), _c('br'), _vm._v(" 스타일리쉬한 세면기부터 웰니스 욕조까지 다양한 제품군을 살펴보고 귀하의 집에 딱 맞는 기분 좋은 제품을 찾아보세요. ")])]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "940"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": item.cols
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": "",
        "href": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("모든 " + _vm._s(item.title) + " 보러 가기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)])], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }